


























































































































































































































































import DialogMixins from '@/components/dialogs/mixins/DialogMixins'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import mixins from 'vue-typed-mixins'

import Section from '@/calendesk/models/DTO/Response/Section'
import CPhoneInput from '@/views/components/CPhoneInput.vue'
import BookingEvent from '@/calendesk/models/BookingEvent'
import BookingDialogModel from '@/calendesk/models/BookingDialogModel'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import BookingCreateRequestData from '@/calendesk/models/DTO/Request/BookingCreateRequestData'
import { mapActions, mapGetters } from 'vuex'
import BookingFormDialogEventsView
  from '@/components/dialogs/components/BookingFormDialog/components/BookingFormEventsView.vue'
import Booking from '@/calendesk/models/DTO/Response/Booking'
import CreateUserRequestData from '@/calendesk/models/DTO/Request/CreateUserRequestData'
import BookingCreateEventRequestData from '@/calendesk/models/DTO/Request/BookingCreateEventRequestData'
import CCountryAutocomplete from '@/components/CCountryAutocomplete.vue'
import CompanyDataActions from '@/calendesk/sections/section/shared/mixins/CompanyDataActions'
import UserDefaultAddressRequestData from '@/calendesk/models/DTO/Request/UserDefaultAddressRequestData'
import FormElements from '@/calendesk/sections/section/shared/components/FormElements.vue'
import {
  email, equalsValue,
  lowercase,
  maxChars,
  minChars,
  number,
  required,
  specialChar,
  uppercase
} from '@/calendesk/forms/validators'
import { resetApp } from '@/calendesk/api/ResetApp'
import Product from '@/calendesk/models/DTO/Response/Product'
import CLocations from '@/components/CLocations.vue'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'

export default mixins(DialogMixins, CompanyDataActions).extend({
  name: 'BookingFormDialog',
  components: { CLocations, FormElements, CCountryAutocomplete, BookingFormDialogEventsView, CPhoneInput },
  data () {
    return {
      selectedLocationV1: null as ServiceLocation | null,
      formIsValid: false,
      inputsDisabled: false,
      wantsAccount: false,
      section: null as Section | null,
      events: [] as Array<BookingEvent>,
      isCreatingBooking: false,
      customFields: [] as Array<Record<string, any>>,
      rules: {
        required,
        email,
        minChars,
        maxChars,
        lowercase,
        uppercase,
        number,
        specialChar,
        equalsValue
      }
    }
  },
  computed: {
    ...mapGetters({
      customerTimeNotation: 'setup/getTimeNotation'
    }),
    showLocationsSectionV1 (): boolean {
      return !this.appConfiguration.schedulesV2enabled &&
        this.firstEvent &&
        this.servicesAreTheSameInCartV1 &&
        this.firstEvent.service &&
        this.firstEvent.service.locations &&
        this.firstEvent.service.locations.length > 0
    },
    servicesAreTheSameInCartV1 (): boolean {
      if (this.events && this.events.length > 0) {
        const serviceId = this.firstEvent.service.id
        return this.events.every(event => event.service && event.service.id === serviceId)
      }

      return true
    },
    selectInitialLocation (): boolean {
      if (this.section && this.section.configuration &&
        typeof this.section.configuration.wb_initial_location_select__checkbox__ !== 'undefined') {
        return this.section.configuration.wb_initial_location_select__checkbox__
      }

      return true
    },
    showBookingNameAndSurnameFields (): boolean {
      if (this.section && this.section.configuration &&
        typeof this.section.configuration.wb_show_name_surname__checkbox__ !== 'undefined') {
        return this.section.configuration.wb_show_name_surname__checkbox__
      }

      return true
    },
    showBookingPhoneNumberField (): boolean {
      if (this.section && this.section.configuration &&
        typeof this.section.configuration.wb_show_phone_number__checkbox__ !== 'undefined') {
        return this.section.configuration.wb_show_phone_number__checkbox__
      }

      return true
    },
    elements (): Array<Record<string, any>> {
      const elements: Array<Record<string, any>> = []
      if (this.section && this.section.configuration) {
        const allElements = this.section.configuration.wb_booking_dynamic_form__dynamic_form__

        if (allElements && allElements.length > 0) {
          allElements.forEach((element: Record<string, any>) => {
            if (element.services && element.services.length > 0) {
              const anyEventWithService = this.events.find(
                (event: BookingEvent) => element.services.includes(event.service.id)
              )
              if (anyEventWithService) {
                elements.push(element)
              }
            } else {
              elements.push(element)
            }
          })
        }
      }

      return elements
    },
    phoneNumberRules (): any[] {
      if (this.section && this.section.configuration) {
        if (this.section.configuration.wb_require_phone_number__checkbox__) {
          return [this.rules.required]
        }
      }

      return []
    },
    canDisplayBillingForm (): boolean {
      return (this.section && this.section.configuration &&
        this.section.configuration.wb_show_billing_data__checkbox__)
    },
    requireBillingData (): boolean {
      return (this.section && this.section.configuration &&
        this.section.configuration.wb_require_billing_data__checkbox__)
    },
    firstEvent (): any {
      if (this.events && this.events.length > 0) {
        return this.events[0]
      }

      return null
    },
    hasServicesWithSubscriptionRequirement (): boolean {
      if (this.events && this.events.length > 0) {
        return !!this.events.find(event => event.service.onlySubscriptions)
      }

      return true
    }
  },
  created () {
    this.section = this.dialog.data.configurationData
    this.events = this.dialog.data.events
  },
  methods: {
    ...mapActions({
      setReloadAllBookings: 'booking/setReloadAllBookings',
      createBooking: 'booking/create',
      fetchUser: 'user/fetchData'
    }),
    sendForm () {
      (this.$refs as any).form.validate()

      if (!this.formIsValid) {
        errorNotification('form_is_invalid', null, false)
        return
      }

      if (this.events.length > 0) {
        this.isCreatingBooking = true
        this.inputsDisabled = true

        const bookingRequests: Array<BookingCreateEventRequestData> = []

        this.events.forEach((item: BookingEvent) => {
          let location = item.location

          if (!this.appConfiguration.schedulesV2enabled) {
            location = this.servicesAreTheSameInCartV1 ? this.selectedLocationV1 : item.location
          }

          const bookingDialogModel = new BookingDialogModel(
            item.service,
            item.employee,
            item.serviceType,
            item.selectedDuration,
            item.startDate,
            item.startTime,
            item.customerTimeZone,
            item.getSelectedProductIds(),
            item.userRequestedEmployee,
            location
          )

          bookingRequests.push(this.createBookingParamsForModel(bookingDialogModel))
        })

        const request = new BookingCreateRequestData(this.createBookingUserParams(), bookingRequests)

        this.createBooking(request).then((bookings: Booking[]) => {
          this.isCreatingBooking = false
          this.inputsDisabled = false

          this.handleSuccess(bookings)
        }).catch((error) => {
          this.isCreatingBooking = false
          this.inputsDisabled = false

          if ((error.response && error.response.data && error.response.data.errors && error.response.data.errors.email)) {
            errorNotification('email_is_taken', error, false)
            return
          }

          if (error && error.response && error.response.status && error.response.data && error.response.data.code) {
            switch (error.response.data.code) {
              case 'USER_BLOCKED':
                errorNotification('account_blocked_message', error, false)
                break
              case 'ACTIVE_SUBSCRIPTION_MISSING':
                if (this.isUserLogged) {
                  errorNotification('active_subscription_missing', error, false)
                } else {
                  errorNotification('login_to_use_service_with_subscription', error, false)
                }
                break
              case 'AUTHENTICATION_REQUIRED':
                errorNotification('employee_auth_required', error, false)
                break
              case 'MAX_NUMBER_OF_BOOKINGS_REACHED':
                errorNotification('max_user_bookings_error', error, false)
                break
              case 'FORBIDDEN':
                if (request.bookings.length > 1) {
                  errorNotification('booking_dialog_date_is_already_taken_multiple_bookings', error, false, 10000)
                } else {
                  errorNotification('booking_dialog_date_is_already_taken', error, false)
                }
                break
              default:

                if (error.response.status === 403) {
                  if (request.bookings.length > 1) {
                    errorNotification('booking_dialog_date_is_already_taken_multiple_bookings', error, false, 10000)
                  } else {
                    errorNotification('booking_dialog_date_is_already_taken', error, false)
                  }
                } else {
                  errorNotification(null, error)
                  resetApp(this.$trans('force_reload_message'))
                }
                break
            }
          } else {
            errorNotification(null, error)
            resetApp(this.$trans('force_reload_message'))
          }
        })
      }
    },
    handleSuccess (bookings: Array<Booking>) {
      this.setReloadAllBookings(true)

      const currentQueryParams = Object.assign({}, this.$route.query)
      currentQueryParams.action = 'booking-created'
      currentQueryParams.booking_ids = bookings.map(booking => booking.id).join(',')

      const totalAmountCents = bookings.reduce((accumulator, currentBooking) => accumulator + currentBooking.getPrice(), 0)
      const totalAmountDecimal = (totalAmountCents / 100)

      currentQueryParams.total_c = totalAmountCents.toString()
      currentQueryParams.total_d = totalAmountDecimal.toFixed(2)

      this.$router.replace({
        path: this.$route.path,
        query: currentQueryParams
      })

      this.$nextTick(() => {
        this.openDialog(new Dialog(
          true,
          DialogTypes.BOOKING_FORM_CONFIRMATION_DIALOG,
          null,
          DialogSize.MIDDLE,
          false,
          'center',
          {
            bookings,
            section: this.section
          },
          true))

        if (this.isUserLogged) {
          this.fetchUser()
        }
      })
    },
    createBookingUserParams () {
      const name = this.name ? this.name : (this.user ? this.user.name : null)
      const surname = this.surname ? this.surname : (this.user ? this.user.surname : null)

      const data = new CreateUserRequestData(
        this.email || this.user.email,
        name,
        surname,
        this.wantsAccount,
        this.phone)

      if (this.canDisplayBillingForm && (this.showBillingData || this.requireBillingData)) {
        const defaultAddress = new UserDefaultAddressRequestData()
        defaultAddress.name = this.billingDataTypeCompany ? this.companyName : null
        defaultAddress.city = this.companyCity
        defaultAddress.street = this.companyStreet
        defaultAddress.tax_number = this.billingDataTypeCompany ? this.companyTaxNumber : null
        defaultAddress.vat_iso_prefix = this.billingDataTypeCompany ? this.companyVatIsoPrefix : null
        defaultAddress.postal_code = this.companyPostalCode
        defaultAddress.country_iso_code = this.companyCountryIsoCode

        data.default_address = defaultAddress
      }

      return data
    },
    createBookingParamsForModel (bookingDialogModel: BookingDialogModel) {
      const customFields = this.customFields.filter((value: any) => !!value)

      return new BookingCreateEventRequestData(
        bookingDialogModel.service.id,
        bookingDialogModel.employee?.id as number,
        bookingDialogModel.date as string,
        bookingDialogModel.time as string,
        bookingDialogModel.serviceType ? bookingDialogModel.serviceType.id : null,
        bookingDialogModel.duration,
        customFields.length > 0 ? JSON.stringify(customFields) : null,
        bookingDialogModel.location?.id as number | null,
        bookingDialogModel.customerTimeZone,
        this.customerTimeNotation,
        bookingDialogModel.selectedSimpleProductIds,
        bookingDialogModel.userRequestedEmployee
      )
    },
    productSelectionChange (data: any) {
      if (data.item && data.product) {
        const selectedProduct: Product = data.product
        const selectedEvent: BookingEvent = data.item
        const isSelected: boolean = data.isSelected

        const clonedEvents = [...this.events]
        const event = clonedEvents.find((event: BookingEvent) => event.id === selectedEvent.id)

        if (event) {
          if (isSelected) {
            event.selectedProducts.push(selectedProduct)
          } else {
            event.selectedProducts = event.selectedProducts.filter(product => product.id !== selectedProduct.id)
          }

          this.events = clonedEvents
        }
      }
    },
    locationSelectionChangeV1 (data: any) {
      if (!this.appConfiguration.schedulesV2enabled) {
        if (data.item && data.location) {
          const selectedEvent: BookingEvent = data.item
          const clonedEvents = [...this.events]
          const event = clonedEvents.find((event: BookingEvent) => event.id === selectedEvent.id)

          if (event) {
            event.location = data.location
            this.events = clonedEvents
          }
        }
      }
    }
  }
})
